import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Wrapper from "../components/wrapper"
import SEO from "../components/seo"
import Logo from "../svg/logo.svg"
import PhotoGallery from "../components/photo-gallery"
import styles from "../styles/home.module.scss"
import heroStyles from "../styles/home-hero.module.scss"
import SpinningIcePourMP4 from "../video/spinning-ice-pour-37-1620p.mp4"
import SpinningIcePourWebm from "../video/spinning-ice-pour-324k-1620p.webm"

const galleryMeta = {
  "food/Margherita Pizza (Urban Romeo Productions).jpg": {
    caption: "Margherita Pizza",
    credit: "Urban Romeo Productions",
    alt: "",
  },
  "food/Purple Cauliflower Bruschetta (Urban Romeo Productions).jpg": {
    caption: "Purple Cauliflower Bruschetta",
    credit: "Urban Romeo Productions",
    alt: "",
  },
  "food/Sweet & Spicy Fritto Misto (Urban Romeo Productions).jpg": {
    caption: "Sweet & Spicy Fritto Misto",
    credit: "Urban Romeo Productions",
    alt: "",
  },
  "food/Spaghetti Carbonara (Urban Romeo Productions).jpg": {
    caption: "Spaghetti Carbonara",
    credit: "Urban Romeo Productions",
    alt: "",
  },
  "food/Beef Meatballs (Urban Romeo Productions).jpg": {
    caption: "Beef Meatballs",
    credit: "Urban Romeo Productions",
    alt: "",
  },
  "food/Brothers Burger (Urban Romeo Productions).jpg": {
    caption: "Brothers Burger",
    credit: "Urban Romeo Productions",
    alt: "",
  },
  "food/Seared King Trumpet Mushrooms (Urban Romeo Productions).jpg": {
    caption: "Seared King Trumpet Mushrooms",
    credit: "Urban Romeo Productions",
    alt: "",
  },
  "food/Beet Down (Urban Romeo Productions).jpg": {
    caption: "Beet Down",
    credit: "Urban Romeo Productions",
    alt: "",
  },
}

const HeroHome = ({ name }) => {
  const [hideContent, setHideContent] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const [videoLoaded, setVideoLoaded] = useState(false)
  const [reduceData, setReduceData] = useState(false)

  useEffect(() => {
    const likesReduced = window.matchMedia(
      "(prefers-reduced-data: reduce)"
    ).matches
    setReduceData(likesReduced)

    // A bit of a hack. Wait for other page elements before rendering video, so
    // long as the browser is ok with data usage
    setShowVideo(true)
  }, [])

  // Tell state that the video has been loaded
  const handleVideoLoaded = () => {
    setVideoLoaded(true)
  }

  // Video element, used multiple times
  const video = (
    <video
      autoPlay={reduceData ? null : true}
      className={`video video-${videoLoaded ? "show" : "hide"}`}
      loop
      muted
      onCanPlayThrough={handleVideoLoaded}
      playsInline
      preload={reduceData ? null : 'auto'}
    >
      <source
        src={`${SpinningIcePourWebm}#t=0`}
        type="video/webm; codecs=vp9,vorbis"
      />
      <source src={`${SpinningIcePourMP4}#t=0`} type="video/mp4" />
    </video>
  )

  return (
    <section id={name} className={heroStyles.hero}>
      <div className="video-bg-blur">{showVideo && video}</div>
      <div className="container">
        <div className={`overlay ${hideContent ? "hide" : "show"}`}></div>
        <div
          className={`content ${hideContent ? "hide" : "show"}`}
          role="banner"
          onDoubleClick={() => {
            setHideContent(!hideContent)
            console.log("%c🥚", "font-size: 36px")
          }}
        >
          <Logo className="logo" />
          <p className="copy">
            Local, Seasonal American and Italian Fare.{" "}
            <span>Full Bar with Craft Cocktails.</span>
          </p>
        </div>
        {showVideo && video}
      </div>
    </section>
  )
}

const IndexPage = () => {
  const galleryQuery = useStaticQuery(graphql`
    query {
      gallery: allFile(filter: { relativeDirectory: { eq: "food" } }) {
        edges {
          node {
            id
            relativePath
            photos: childImageSharp {
              id
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                placeholder: BLURRED
                width: 2400
                quality: 70
                avifOptions: { quality: 55 }
                outputPixelDensities: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]
              )
            }
            thumbnails: childImageSharp {
              id
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                placeholder: BLURRED
                width: 1640
                quality: 70
                avifOptions: { quality: 55 }
                aspectRatio: 1
                transformOptions: { cropFocus: ENTROPY }
                outputPixelDensities: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]
              )
              resize(height: 154) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const galleryData = galleryQuery.gallery

  return (
    <Layout nowrap style={styles.homeLayout}>
      <SEO title="Now Open!" />
      <Wrapper className={styles.home}>
        <HeroHome name="intro" />
      </Wrapper>
      <Wrapper sizeMax className={styles.home}>
        <Wrapper sizeReadable>
          <h2>Brothers Public Kitchen is open!</h2>
          <p className="intro">
            We are located in Theatre Square in the heart of Downtown Orinda and
            just a short walk from Orinda BART Station.{" "}
            <a href="/menu/">Our menu</a> changes with the seasons,
            and we have a full bar serving craft cocktails, beers on draft, and
            wine. Join us for indoor dinning or on our spacious patio.{" "}
          </p>
          <p>
            <Link to="/story/">See our story here.</Link>
          </p>
          <PhotoGallery
            galleryData={galleryData.edges}
            galleryMeta={galleryMeta}
            group="food"
            thumbSrcSizes="(min-width: 818px) 380px, (min-width: 992px) 444px, 45vw"
          />
        </Wrapper>
      </Wrapper>
    </Layout>
  )
}

export default IndexPage
